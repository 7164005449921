import styled from '@emotion/styled'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Button, CircularProgress, Collapse, Stack, Typography } from '@mui/material'
import { getQueryResults } from 'api/query'
import { useDrawersContext } from 'components/context/ClientsDrawersProvider'
import { useCollapseContext } from 'features/UI'
import iconSmQuestion from 'images/icons/icon_sm_question.png'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { QueryResult } from 'types'
import QueryItem from './QueryItem'

interface Props {
  title: string
  id: number
  bannerUrl: string | null
  takenTimes?: number
}

const sx = {
  background: 'white',
  paddingY: 2.5,
  paddingX: 2,
  borderRadius: 2,
  width: '100%',
  cursor: 'pointer',
}

const IconContainer = styled('div')`
  width: 57px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
`

const RightIconContainer = styled.div`
  width: 24px;
`

const QueryGroup = (props: Props) => {
  const { title, id, bannerUrl, takenTimes } = props
  const { handleOpenQuestioning: handleOpenQuestionnaire } = useDrawersContext()
  const { activeCollapseId, setActiveCollapse } = useCollapseContext()

  const [queryResults, setQueryResults] = useState<QueryResult[]>()
  const [queryResultCount, setQueryResultCount] = useState<number>(takenTimes || 0)

  const isOpen = useMemo(() => {
    return id === activeCollapseId
  }, [id, activeCollapseId])

  const request = useQuery(['getQueryResults', { id }], () => getQueryResults(id), {
    enabled: false,
    onSuccess: (e) => {
      setQueryResults(e.data)
      setQueryResultCount(e.data.length)
      expand()
    },
  })

  const handleTakeQueryClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      handleOpenQuestionnaire({
        id: id,
        mode: 'take',
        type: 'query',
        onQuestioningTaken: () => {
          request.refetch()
        },
      })
    },
    [handleOpenQuestionnaire, id, request],
  )

  const expand = useCallback(() => {
    setActiveCollapse(id)
  }, [id, setActiveCollapse])

  const collapse = useCallback(() => {
    setActiveCollapse(null)
  }, [setActiveCollapse])

  const handleCollapseClick = useCallback(() => {
    if (isOpen) {
      collapse()
      return
    }

    if (queryResults) {
      expand()
      return
    }

    request.refetch()
  }, [isOpen, queryResults, request, collapse, expand])

  const iconUrl = bannerUrl || iconSmQuestion
  const buttonText = queryResultCount > 0 ? 'Retake' : 'Take'

  return (
    <Stack sx={sx} display="flex" spacing={2} my={1}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        onClick={handleCollapseClick}
      >
        <IconContainer>
          <img src={iconUrl} />
        </IconContainer>
        <Stack sx={{ flex: 1 }}>
          <Typography sx={{ flex: 1 }} fontWeight={400} variant="h6">
            {title}
          </Typography>
          {queryResultCount > 0 && (
            <Typography variant="subtitle2" fontWeight={200}>
              Taken {queryResultCount} times
            </Typography>
          )}
        </Stack>
        <Button variant="contained" color="secondary" size="small" onClick={handleTakeQueryClick}>
          {buttonText}
        </Button>
        <RightIconContainer>
          {request.isLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            <KeyboardArrowDown
              sx={{
                transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
              }}
            />
          )}
        </RightIconContainer>
      </Stack>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Stack spacing={1} p={1} borderRadius={2} sx={{ background: '#ccc' }}>
          {queryResults?.length == 0 && 'There are no results'}
          {!!queryResults?.length &&
            queryResults.map((q) => {
              return (
                <QueryItem
                  key={q.id}
                  id={id}
                  title={title}
                  passedOn={q.completedAt}
                  passedBy={q.user.firstname + ' ' + q.user.lastname}
                  answers={q.answeredQuestions}
                  bannerUrl={bannerUrl}
                />
              )
            })}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default QueryGroup
